import {keys, map} from 'lodash';
import {ReactNode, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';

export function usePrerenderMetaTags<T>(
  data: T | null | undefined,
  generateTagsMap: (data: T) => {[key: string]: string | null | undefined},
) {
  const [metaTags, setMetaTags] = useState<ReactNode>(null);

  useEffect(() => {
    if (data) {
      const tagsMap = generateTagsMap(data);
      setMetaTags(
        <Helmet>
          {map(keys(tagsMap), (key) => (
            <meta key={key} name={key} content={tagsMap[key] || ''} />
          ))}
        </Helmet>,
      );
      // @ts-expect-error
      window.prerenderReady = true;
    } else {
      // @ts-expect-error
      window.prerenderReady = false;
    }
  }, [data, generateTagsMap]);

  return metaTags;
}
