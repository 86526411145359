import axios from 'axios';
import {format, formatDistanceStrict} from 'date-fns';
import {ReactNode} from 'react';
import {CONFIG} from 'src/constants';

export async function sleep(ms: number) {
  return new Promise((r) => setTimeout(r, ms));
}

export function uriToJson(uri: string) {
  return JSON.parse(window.atob(uri.split(',')[1]));
}

type ConditionalWrapperProps = {
  wrap: boolean;
  wrapper: (children: ReactNode) => JSX.Element;
  children: ReactNode;
};
export function ConditionalWrapper(props: ConditionalWrapperProps) {
  return props.wrap ? props.wrapper(props.children) : <>{props.children}</>;
}

export function toDateDistance(timestamp: string) {
  return formatDistanceStrict(Date.parse(timestamp), new Date(), {addSuffix: true});
}

export function toDateString(timestamp: string) {
  return format(Date.parse(timestamp), 'yyyy-MM-dd');
}

export async function uploadImageViaUrl({url, jwt}: {url: string; jwt: string}) {
  const {data} = await axios.post(
    CONFIG.SERVER_URL + '/upload-via-url',
    {url},
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );

  return data;
}
