import {Button, ButtonProps, Tooltip} from 'antd';
import {useCallback, useState} from 'react';

export type BusyButtonProps = ButtonProps & {disabledReason?: string};
const BusyButton = (props: BusyButtonProps) => {
  const [busy, setBusy] = useState(false);

  const handleClick = useCallback(
    async (e: any) => {
      if (props.onClick) {
        setBusy(true);
        try {
          await props.onClick(e);
        } catch (err) {
          throw err;
        } finally {
          setBusy(false);
        }
      }
    },
    [props, setBusy],
  );

  const {disabledReason, children, ...rest} = props;

  const button = (
    <Button {...rest} onClick={handleClick} loading={busy || props.loading}>
      <div style={{display: 'inline-flex', alignItems: 'center'}}>{children}</div>
    </Button>
  );

  if (disabledReason && props.disabled) {
    return <Tooltip title={disabledReason}>{button}</Tooltip>;
  } else {
    return button;
  }
};

export default BusyButton;
