import {ApolloProvider} from '@apollo/client';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import 'antd/dist/antd.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';
import {SingletonHooksContainer} from 'react-singleton-hook';
import {client} from 'src/utils/apollo';
import App from './App';
import {CONFIG, ENV} from './constants';
import reportWebVitals from './reportWebVitals';
import './index.scss';

if (CONFIG.SENTRY_URL) {
  Sentry.init({
    dsn: CONFIG.SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <SingletonHooksContainer />
          <App />
        </ApolloProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (ENV === 'dev') {
  reportWebVitals(console.log);
}
