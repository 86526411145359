const RATING_EMOJI: Record<string, string> = {
  '-1': '😒',
  '0': '',
  '1': '🙂',
  '2': '😀',
  '3': '🤩',
};

const TEXT: Record<string, string> = {
  '-1': "Wouldn't get again",
  '0': '',
  '1': "It's OK",
  '2': 'Good',
  '3': 'Amazing!',
};

type RatingProps = {
  rating: number;
  showText?: boolean;
};

export function getRatingEmoji(rating: number) {
  return RATING_EMOJI[String(rating)];
}

export default function Rating({rating, showText = false}: RatingProps) {
  return (
    <div style={{display: 'inline-flex', flexDirection: 'column', alignItems: 'center'}}>
      <div style={{fontSize: 18}}>{getRatingEmoji(rating)}</div>
      {showText && <div>{TEXT[rating]}</div>}
    </div>
  );
}
