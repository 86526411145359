import {useEffect} from 'react';
import {Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import {Redirect} from 'src/components';
import AccountsDelete from './AccountsDelete';
import Auth from './Auth';
import AuthRequired from './AuthRequired';
import Home from './Home';
import ReviewsCreate from './reviews/Create';
import ReviewsShow from './reviews/Show';
import UsersShow from './users/Show';
import ReviewsBatchCreate from './reviews/BatchCreate';

export default function ViewsRoutes() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (/[A-Z]+/.test(location.pathname)) {
      navigate([location.pathname.toLowerCase(), location.search].join(''), {replace: true});
    }
  }, [location, navigate]);

  return (
    <Routes>
      <Route path='/auth' element={<Auth />} />
      <Route path='reviews/*'>
        <Route path=':reviewId' element={<ReviewsShow />} />
        <Route path='_create' element={<ReviewsCreate />} />
        <Route path='_batch-create' element={<ReviewsBatchCreate />} />
      </Route>
      <Route path='users/*'>
        <Route path=':name' element={<UsersShow />} />
      </Route>
      <Route path='accounts/*'>
        <Route
          path='delete'
          element={
            <AuthRequired>
              <AccountsDelete />
            </AuthRequired>
          }
        />
      </Route>

      <Route path='/_home' element={<Home />} />
      <Route path='/' element={<Redirect to='/get' />} />
    </Routes>
  );
}
