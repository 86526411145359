import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Button, Card, Form, Input} from 'antd';
import {isString, trim} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {titleCase} from 'title-case';
import {BusyButton} from 'src/components';
import {CreateReviewDocument, MeDocument, ReviewPlaceDocument, ReviewPlaceQuery} from 'src/graphql';
import {useAuth, useQueryParams} from 'src/hooks';
import {uploadImageViaUrl} from 'src/utils';

gql`
  query Me($userId: String!) {
    users_by_pk(id: $userId) {
      id
      name
    }
  }
`;

gql`
  query ReviewPlace($placeId: Int!) {
    places_by_pk(id: $placeId) {
      id
      name
      reviews {
        id
        name
        user {
          id
          name
        }
      }
    }
  }
`;

gql`
  mutation CreateReview(
    $name: String!
    $rating: Int!
    $placeId: Int!
    $photoUrl: String!
    $comment: String
  ) {
    insert_reviews_one(
      object: {
        name: $name
        rating: $rating
        placeId: $placeId
        photoUrl: $photoUrl
        comment: $comment
      }
    ) {
      id
    }
  }
`;

function NewReview({
  placeId,
  name: _name,
  rating: _rating,
  comment: _comment,
}: {
  placeId?: string;
  name?: string;
  rating?: number;
  comment?: string;
}) {
  const {jwt} = useAuth();
  const [name, setName] = useState(_name || '');
  const [rating, setRating] = useState(_rating || 0);
  const [uploadPhotoUrl, setUploadPhotoUrl] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [reviewUrl, setReviewUrl] = useState('');
  const [comment, setComment] = useState(_comment || '');
  const [createReview] = useMutation(CreateReviewDocument);

  const isValid = useMemo(() => {
    return (
      trim(name).length > 0 &&
      trim(photoUrl).length > 0 &&
      photoUrl.startsWith('https://') &&
      rating >= -1 &&
      rating <= 3 &&
      rating !== 0 &&
      !!placeId
    );
  }, [photoUrl, name, placeId, rating]);

  const isValidUploadUrl = useMemo(() => {
    try {
      new URL(uploadPhotoUrl);
      return true;
    } catch (e) {
      return false;
    }
  }, [uploadPhotoUrl]);

  const handleUpload = useCallback(async () => {
    const {url} = await uploadImageViaUrl({
      url: uploadPhotoUrl,
      jwt: jwt || '',
    });

    setPhotoUrl(url);
  }, [uploadPhotoUrl, jwt]);

  const handlePost = useCallback(async () => {
    if (!placeId) {
      return;
    }

    try {
      const {data} = await createReview({
        variables: {
          name: trim(titleCase(name)),
          rating,
          photoUrl: trim(photoUrl),
          placeId: parseInt(placeId, 10),
          comment: trim(comment),
        },
      });
      setReviewUrl(`/reviews/${data?.insert_reviews_one?.id}`);
    } catch (err) {
      alert(err);
    }
  }, [comment, createReview, name, photoUrl, placeId, rating]);

  if (reviewUrl) {
    return <Link to={reviewUrl}>{reviewUrl}</Link>;
  }

  return (
    <Form>
      <Form.Item label='Name'>
        <Input placeholder='Food name' value={name} onChange={(e) => setName(e.target.value)} />
      </Form.Item>
      <Form.Item label='Rating'>
        <Input
          type='number'
          placeholder='Rating'
          value={rating}
          onChange={(e) => setRating(parseInt(e.target.value, 10))}
        />
      </Form.Item>
      <Form.Item label='Upload URL'>
        <Input
          placeholder='Upload Image URL'
          value={uploadPhotoUrl}
          onChange={(e) => setUploadPhotoUrl(e.target.value)}
        />
        <BusyButton disabled={!isValidUploadUrl} onClick={handleUpload}>
          Upload
        </BusyButton>
      </Form.Item>
      <Form.Item label='Image URL'>
        <Input
          placeholder='Image URL'
          value={photoUrl}
          onChange={(e) => setPhotoUrl(e.target.value)}
        />
      </Form.Item>
      <Form.Item label='Comment'>
        <Input.TextArea
          rows={3}
          placeholder='Comment'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Form.Item>

      <BusyButton disabled={!isValid || !!reviewUrl} type='primary' onClick={handlePost}>
        Post
      </BusyButton>
    </Form>
  );
}

export default function ReviewsBatchCreate() {
  const {placeId: queryPlaceId} = useQueryParams();

  const {isAuthed, userId} = useAuth();
  const {data: meData} = useQuery(MeDocument, {variables: {userId: userId || ''}});
  const [fetchPlace, {data: placeData}] = useLazyQuery(ReviewPlaceDocument);
  const [place, setPlace] = useState<ReviewPlaceQuery['places_by_pk']>();
  const [reviewsJson, setReviewsJson] = useState('');
  const [reviews, setReviews] = useState<{name: string; comment: string; rating: number}[]>([]);

  useEffect(() => {
    (async () => {
      if (isString(queryPlaceId)) {
        const {data} = await fetchPlace({
          variables: {placeId: parseInt(queryPlaceId, 10)},
        });
        setPlace(data?.places_by_pk);
      }
    })();
  }, [fetchPlace, queryPlaceId]);

  const handleParseReviews = useCallback(() => {
    let parsed = JSON.parse(reviewsJson);
    if (!Array.isArray(parsed)) {
      parsed = parsed.dishes;
    }

    setReviews(
      parsed.map((d: any) => ({
        name: d.name,
        rating: d.score - 1 === 0 ? -1 : d.score - 1,
        comment: d.review,
      })),
    );
  }, [reviewsJson]);

  // if (!isAuthed) {
  //   return <Link to='/_home'>Please Auth</Link>;
  // }

  if (!place) {
    return <div>Pass in a placeId to the query string, e.g. ?placeId=1</div>;
  }

  console.log('reviews', reviews);

  return (
    <div>
      <h2>{meData?.users_by_pk?.name}</h2>
      <Input.TextArea
        style={{fontFamily: 'monospace'}}
        rows={10}
        placeholder='Paste reviews JSON here'
        value={reviewsJson}
        onChange={(e) => setReviewsJson(e.target.value)}
      />
      <Button onClick={handleParseReviews}>Parse</Button>

      {placeData?.places_by_pk && (
        <div>
          <h3>{placeData.places_by_pk.name}</h3>
          {placeData.places_by_pk.reviews.map((review) => (
            <div key={review.id}>
              <h4>
                {review.name} by {review.user.name}
              </h4>
            </div>
          ))}
        </div>
      )}

      {reviews.map((review, i) => (
        <div key={i}>
          <Card style={{border: '1px solid #ccc', marginBottom: 30}}>
            <NewReview
              name={review.name}
              rating={review.rating}
              comment={review.comment}
              placeId={place.id.toString()}
            />
          </Card>
        </div>
      ))}
    </div>
  );
}
