import jwtDecode from 'jwt-decode';

function getDecodedJwt() {
  const jwt = getAuthToken();
  if (!jwt) {
    return null;
  }

  try {
    const decoded = jwtDecode<{sub: string; exp: number}>(jwt);
    return isExpired(decoded.exp) ? null : decoded;
  } catch (err) {
    return null;
  }
}

function isExpired(exp?: number) {
  return (exp || 0) <= Date.now() / 1000;
}

export function getAuthToken() {
  return JSON.parse(localStorage.getItem('jwt') || 'null');
}

export function clearAuthToken() {
  localStorage.removeItem('jwt');
}

export function getUserId() {
  return getDecodedJwt()?.sub;
}

export function isAuthed() {
  return !!getDecodedJwt();
}
