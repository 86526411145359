import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Form, Input} from 'antd';
import {debounce, trim} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {titleCase} from 'title-case';
import {BusyButton} from 'src/components';
import {CreateReviewDocument, MeDocument, ReviewPlaceDocument} from 'src/graphql';
import {useAuth, useQueryParams} from 'src/hooks';
import {uploadImageViaUrl} from 'src/utils';

gql`
  query Me($userId: String!) {
    users_by_pk(id: $userId) {
      id
      name
    }
  }
`;

gql`
  query ReviewPlace($placeId: Int!) {
    places_by_pk(id: $placeId) {
      id
      name
      reviews {
        id
        name
        user {
          id
          name
        }
      }
    }
  }
`;

gql`
  mutation CreateReview(
    $name: String!
    $rating: Int!
    $placeId: Int!
    $photoUrl: String!
    $comment: String
  ) {
    insert_reviews_one(
      object: {
        name: $name
        rating: $rating
        placeId: $placeId
        photoUrl: $photoUrl
        comment: $comment
      }
    ) {
      id
    }
  }
`;

export default function ReviewsCreate() {
  const {placeId: queryPlaceId} = useQueryParams();

  const {isAuthed, userId, jwt} = useAuth();
  const [name, setName] = useState('');
  const [rating, setRating] = useState(0);
  const [uploadPhotoUrl, setUploadPhotoUrl] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [placeId, setPlaceId] = useState(queryPlaceId as string | undefined);
  const [reviewUrl, setReviewUrl] = useState('');
  const [comment, setComment] = useState('');
  const {data: meData} = useQuery(MeDocument, {variables: {userId: userId || ''}});
  const [fetchPlace, {data: placeData}] = useLazyQuery(ReviewPlaceDocument);
  const [createReview] = useMutation(CreateReviewDocument);

  const debouncedFetchPlace = useCallback(debounce(fetchPlace, 500), [fetchPlace]);

  useEffect(() => {
    (async () => {
      if (placeId) {
        debouncedFetchPlace({variables: {placeId: parseInt(placeId, 10)}});
      }
    })();
  }, [debouncedFetchPlace, placeId]);

  const isValid = useMemo(() => {
    return (
      trim(name).length > 0 &&
      trim(photoUrl).length > 0 &&
      photoUrl.startsWith('https://') &&
      rating >= -1 &&
      rating <= 3 &&
      rating !== 0 &&
      !!placeId
    );
  }, [photoUrl, name, placeId, rating]);

  const isValidUploadUrl = useMemo(() => {
    try {
      new URL(uploadPhotoUrl);
      return true;
    } catch (e) {
      return false;
    }
  }, [uploadPhotoUrl]);

  const handleUpload = useCallback(async () => {
    const {url} = await uploadImageViaUrl({
      url: uploadPhotoUrl,
      jwt: jwt || '',
    });

    setPhotoUrl(url);
  }, [uploadPhotoUrl, jwt]);

  const handlePost = useCallback(async () => {
    if (!placeId) {
      return;
    }

    try {
      const {data} = await createReview({
        variables: {
          name: trim(titleCase(name)),
          rating,
          photoUrl: trim(photoUrl),
          placeId: parseInt(placeId, 10),
          comment: trim(comment),
        },
      });
      setReviewUrl(`/reviews/${data?.insert_reviews_one?.id}`);
    } catch (err) {
      alert(err);
    }
  }, [comment, createReview, name, photoUrl, placeId, rating]);

  if (!isAuthed) {
    return <Link to='/_home'>Please Auth</Link>;
  }

  return (
    <div>
      <h2>{meData?.users_by_pk?.name}</h2>
      {reviewUrl && <Link to={reviewUrl}>{reviewUrl}</Link>}
      <Form disabled={!!reviewUrl}>
        <Form.Item label='Name'>
          <Input placeholder='Food name' value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item label='Rating'>
          <Input
            type='number'
            placeholder='Rating'
            value={rating}
            onChange={(e) => setRating(parseInt(e.target.value, 10))}
          />
        </Form.Item>
        <Form.Item label='Upload URL'>
          <Input
            placeholder='Upload Image URL'
            value={uploadPhotoUrl}
            onChange={(e) => setUploadPhotoUrl(e.target.value)}
          />
          <BusyButton disabled={!isValidUploadUrl} onClick={handleUpload}>
            Upload
          </BusyButton>
        </Form.Item>
        <Form.Item label='Image URL'>
          <Input
            placeholder='Image URL'
            value={photoUrl}
            onChange={(e) => setPhotoUrl(e.target.value)}
          />
        </Form.Item>
        <Form.Item label='Place ID'>
          <Input
            placeholder='Place ID'
            value={placeId}
            onChange={(e) => setPlaceId(e.target.value)}
          />
        </Form.Item>
        <Form.Item label='Comment'>
          <Input.TextArea
            rows={3}
            placeholder='Comment'
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Item>

        <BusyButton disabled={!isValid || !!reviewUrl} type='primary' onClick={handlePost}>
          Post
        </BusyButton>
      </Form>

      {placeData?.places_by_pk && (
        <div>
          <h3>{placeData.places_by_pk.name}</h3>
          {placeData.places_by_pk.reviews.map((review) => (
            <div key={review.id}>
              <h4>
                {review.name} by {review.user.name}
              </h4>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
