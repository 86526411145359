import {gql, useQuery} from '@apollo/client';
import {Button, Skeleton} from 'antd';
import axios from 'axios';
import {useCallback, useState} from 'react';
import ReactGA from 'react-ga';
import {CONFIG} from 'src/constants';
import {MyAccountDocument} from 'src/graphql';
import {useAuth} from 'src/hooks';

gql`
  query MyAccount($userId: String!) {
    users_by_pk(id: $userId) {
      id
      name
    }
  }
`;

export default function AccountDeletion() {
  const [done, setDone] = useState(false);
  const {userId} = useAuth();

  const {data, loading} = useQuery(MyAccountDocument, {variables: {userId: userId || ''}});

  const handleDelete = useCallback(async () => {
    if (window.confirm('Are you really sure?')) {
      ReactGA.event({
        category: 'Account',
        action: 'Delete',
        label: userId,
      });
      await axios.post(CONFIG.SERVER_URL + '/delete-account', {userId});

      setDone(true);
    }
  }, [userId]);

  return (
    <Skeleton loading={loading} active>
      <h1>Account Deletion: {data?.users_by_pk?.name}</h1>
      {done ? (
        <p>
          Your account has been marked for deletion. It can take up to 24 hours for this to
          complete.
        </p>
      ) : (
        <>
          <p>Are you sure you want to delete your account?</p>
          <Button onClick={handleDelete} type='primary' danger>
            Yes, delete my account
          </Button>
        </>
      )}
    </Skeleton>
  );
}
