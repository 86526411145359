import {useAuth0} from '@auth0/auth0-react';
import {Button, Col, Row, Skeleton} from 'antd';
import {useCallback} from 'react';
import {useAuth} from 'src/hooks';

export default function Home() {
  const {loginWithRedirect, isAuthenticated, logout, user, isLoading} = useAuth0();
  const {setAuth} = useAuth();

  const handleLoginLogout = useCallback(() => {
    if (isAuthenticated) {
      setAuth(null);
      logout({returnTo: window.location.origin});
    } else {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, logout, setAuth]);

  console.log('user', user);
  // useEffect(() => {
  //   async function run() {
  //     if (!isAuthenticated) {
  //       return;
  //     }

  //     const token = await getAccessTokenSilently({
  //       audience: 'hasura',
  //     });
  //     console.log('token', token);
  //   }
  //   run();
  // }, [isAuthenticated]);

  return (
    <Skeleton loading={isLoading}>
      <Row gutter={[16, 16]}>
        <Col>
          {!isLoading && (
            <Button onClick={handleLoginLogout}>{isAuthenticated ? 'Log Out' : 'Log In'}</Button>
          )}
        </Col>
        <p style={{fontSize: 24}}>👋🏻</p>
      </Row>
    </Skeleton>
  );
}
