const Tokens: {[key: string]: {name: string; image: string}} = {
  1001: {
    name: 'Love It',
    image: '/metadata/hypey-tokens/1001.png',
  },
  1002: {
    name: 'Money Wings',
    image: '/metadata/hypey-tokens/1002.png',
  },
  1003: {
    name: 'LOL',
    image: '/metadata/hypey-tokens/1003.png',
  },
  1004: {
    name: 'Face Palm',
    image: '/metadata/hypey-tokens/1004.png',
  },
  1005: {
    name: 'Kowtow',
    image: '/metadata/hypey-tokens/1005.png',
  },
  1006: {
    name: 'WTF',
    image: '/metadata/hypey-tokens/1006.png',
  },
};

type Props = {
  hypeyTokenId: string | number | null;
  width?: number | string;
  showLabel?: boolean;
  className?: string;
};

export const Ids = Object.keys(Tokens);

export default function HypeyToken(props: Props) {
  if (!props.hypeyTokenId) {
    return null;
  }

  const token = Tokens[props.hypeyTokenId];

  return (
    <div
      className={props.className}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <img width={props.width} src={token.image} alt={token.name} title={token.name} />
      {props.showLabel && (
        <div style={{textAlign: 'center', fontSize: 12, fontWeight: 600}}>{token.name}</div>
      )}
    </div>
  );
}
