import {useEffect, useState} from 'react';
import ReactGA from 'react-ga';
import {useLocation} from 'react-router-dom';

export function usePageTracking() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (window.location.host === 'fudee.app') {
      ReactGA.initialize('UA-243815074-1');
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
}
