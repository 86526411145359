import {CONFIG} from '../constants';
import {ApolloClient, InMemoryCache, split, HttpLink, from} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {WebSocketLink} from '@apollo/client/link/ws';
import {offsetLimitPagination} from '@apollo/client/utilities';
import {getMainDefinition} from '@apollo/client/utilities';
import {find} from 'lodash';
import {clearAuthToken, getAuthToken, isAuthed} from 'src/lib/Auth';

const httpLink = new HttpLink({
  uri: CONFIG?.GRAPHQL_URI,
});

const authErrorLink = onError(({graphQLErrors, operation, forward}) => {
  if (find(graphQLErrors, (err) => err.extensions.code === 'invalid-jwt')) {
    clearAuthToken();
    const oldHeaders = operation.getContext().headers;
    operation.setContext({
      headers: {
        ...oldHeaders,
        authorization: undefined,
      },
    });
    // Retry the request, returning the new observable
    return forward(operation);
  }
});

const authLink = setContext((_, {headers}) => {
  const token = getAuthToken();
  return {
    headers: {
      ...headers,
      ...(token && {Authorization: token ? `Bearer ${token}` : ''}),
    },
  };
});

const wsLink = new WebSocketLink({
  uri: CONFIG?.GRAPHQL_SUBSCRIPTIONS_URI || '',
  options: {
    lazy: true,
    reconnect: true,
    timeout: 30000,
    minTimeout: 5000,
    connectionParams: async () => {
      if (isAuthed()) {
        return {
          headers: {Authorization: `Bearer ${getAuthToken()}`},
        };
      }
    },
  },
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({query}) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);

export const client = new ApolloClient({
  link: from([authErrorLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          nfts: offsetLimitPagination(['where', 'order_by']),
          contracts: offsetLimitPagination(['where', 'order_by']),
        },
      },
    },
  }),
});
