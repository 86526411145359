import {gql, useQuery} from '@apollo/client';
import {Avatar, Card, Col, Row, Skeleton} from 'antd';
import {useParams} from 'react-router-dom';
import verifiedPng from 'src/assets/verified.png';
import {UsersShowDocument, UsersShowQuery} from 'src/graphql';
import {usePrerenderMetaTags} from 'src/hooks';

gql`
  query UsersShow($name: String!) {
    users(where: {name: {_eq: $name}}, limit: 1) {
      id
      name
      photoUrl
      verifiedAt
      reviews {
        id
        name
        photoUrl
      }
    }
  }
`;

function generateMetaTags(user: NonNullable<UsersShowQuery['users'][number]>) {
  return {
    'og:title': user.name,
    'og:description': `Reviews by ${user.name}`,
    'og:image': user.photoUrl,
  };
}

export default function UsersShow() {
  const {name} = useParams<'name'>();
  const {data, loading} = useQuery(UsersShowDocument, {
    variables: {name: name || ''},
  });
  const user = data?.users?.[0];

  const metaTags = usePrerenderMetaTags(user, generateMetaTags);

  return (
    <>
      <Skeleton loading={loading}>
        {user && (
          <>
            <Card
              title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Avatar style={{marginRight: 10}} src={user.photoUrl} />
                  {user.name}
                  {user.verifiedAt && (
                    <img
                      style={{marginLeft: 5, height: 18}}
                      src={verifiedPng}
                      alt='verified'
                      title='Verified'
                    />
                  )}
                </div>
              }>
              <Row gutter={[20, 20]}>
                {user.reviews.map((review) => (
                  <Col span={8}>
                    <Card
                      key={review.id}
                      title={review.name}
                      cover={
                        <img
                          style={{height: 100, objectFit: 'cover'}}
                          src={review.photoUrl || undefined}
                          alt={review.name}
                        />
                      }
                    />
                  </Col>
                ))}
              </Row>
            </Card>
          </>
        )}
      </Skeleton>
      {metaTags}
    </>
  );
}
