import {useAuth0} from '@auth0/auth0-react';
import {Button} from 'antd';
import {useCallback, useEffect} from 'react';
import {useAuth} from 'src/hooks';

export default function AuthRequired({children}: {children: React.ReactNode}) {
  const {loginWithRedirect} = useAuth0();
  const {isAuthed, jwt} = useAuth();

  const handleLogin = useCallback(() => {
    loginWithRedirect({appState: {returnTo: window.location.pathname}});
  }, [loginWithRedirect]);

  useEffect(() => {
    console.log('isauthed', isAuthed, jwt);
    if (!isAuthed) {
      handleLogin();
    }
  }, [handleLogin, isAuthed, jwt, loginWithRedirect]);

  return isAuthed ? <>{children}</> : <Button onClick={handleLogin}>Log In</Button>;
}
