import {useAuth0} from '@auth0/auth0-react';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from 'src/hooks';
import localState from 'src/lib/localState';

export default function Auth() {
  const {isAuthenticated, getAccessTokenSilently} = useAuth0();
  const {setAuth} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function run() {
      console.log('/auth', isAuthenticated, window.location.href);
      if (!isAuthenticated) {
        return;
      }

      const {returnTo} = localState;
      console.log('returnTo', returnTo);

      const token = await getAccessTokenSilently({
        audience: 'hasura',
      });
      console.log('token', token);
      console.log('set auth', token);
      setAuth(token);
      navigate(returnTo || '/', {replace: true});
    }
    run();
  }, [getAccessTokenSilently, isAuthenticated, navigate, setAuth]);

  return null;
}
