export const ENV: keyof typeof CONFIGS = (process.env.REACT_APP_ENV || 'dev') as any;

type ConfigOption = {
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  SENTRY_URL: string | null;
  SERVER_URL: string;
  GRAPHQL_URI: string;
  GRAPHQL_SUBSCRIPTIONS_URI: string;
};
const CONFIGS: {[key: string]: ConfigOption} = {
  dev: {
    AUTH0_DOMAIN: 'dev-gzztszge.us.auth0.com',
    AUTH0_CLIENT_ID: 'Nf4MCpaQtApRvow405BdWIEnIEMwdAWQ',
    SENTRY_URL: null,
    SERVER_URL: 'http://localhost:8888',
    GRAPHQL_URI: 'http://localhost:8080/v1/graphql',
    GRAPHQL_SUBSCRIPTIONS_URI: 'ws://localhost:8080/v1/graphql',
  },
  production: {
    AUTH0_DOMAIN: 'fudee.us.auth0.com',
    AUTH0_CLIENT_ID: 'fCsOD4FY51z3CjUZ5iSwwLHnCkg0c7uJ',
    SENTRY_URL: 'https://a5db0cbbe1714c4da1d7b0ea73b3c658@o1117646.ingest.sentry.io/6305647',
    SERVER_URL: 'https://fudee-production.herokuapp.com',
    GRAPHQL_URI: 'https://fudee.hasura.app/v1/graphql',
    GRAPHQL_SUBSCRIPTIONS_URI: 'wss://fudee.hasura.app/v1/graphql',
  },
};

export const CONFIG = CONFIGS[ENV];
