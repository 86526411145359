import {gql, useQuery} from '@apollo/client';
import {Avatar, Card, Comment, Image, Skeleton} from 'antd';
import {isEmpty} from 'lodash';
import {useParams} from 'react-router-dom';
import verifiedPng from 'src/assets/verified.png';
import {Rating} from 'src/components';
import {getRatingEmoji} from 'src/components/Rating';
import {ReviewsShowDocument, ReviewsShowQuery} from 'src/graphql';
import {usePrerenderMetaTags} from 'src/hooks';
import {toDateDistance} from 'src/utils';

gql`
  query ReviewsShow($reviewId: Int!) {
    reviews_by_pk(id: $reviewId) {
      id
      name
      photoUrl
      rating
      comment
      user {
        name
        photoUrl
        verifiedAt
      }
      place {
        id
        name
        coverUrl
        address
        googlePlaceId
      }
      comments: reactions(where: {type: {_is_null: true}}, limit: 50, order_by: {createdAt: desc}) {
        id
        comment
        createdAt
        user {
          name
          photoUrl
          verifiedAt
        }
      }
    }
  }
`;

function generateMetaTags(review: NonNullable<ReviewsShowQuery['reviews_by_pk']>) {
  return {
    'og:title': `${review.name} from ${review.place.name}`,
    'og:description': `${getRatingEmoji(review.rating)} ${review.comment}`,
    'og:image': review.photoUrl,
  };
}

export default function ReviewsShow() {
  const {reviewId} = useParams<'reviewId'>();
  const {data, loading} = useQuery(ReviewsShowDocument, {
    variables: {reviewId: parseInt(reviewId as string)},
  });
  const {reviews_by_pk: review} = data || {};

  const metaTags = usePrerenderMetaTags(review, generateMetaTags);

  return (
    <>
      <Skeleton loading={loading}>
        {review && (
          <>
            <Card
              title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Avatar style={{marginRight: 10}} src={review.user.photoUrl} />
                  {review.user.name}
                  {review.user.verifiedAt && (
                    <img
                      style={{marginLeft: 5, height: 18}}
                      src={verifiedPng}
                      alt='verified'
                      title='Verified'
                    />
                  )}
                </div>
              }
              cover={
                <Image
                  style={{minHeight: 400, maxHeight: '65vh', objectFit: 'cover'}}
                  src={review.photoUrl || undefined}
                />
              }>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{fontSize: 20, fontWeight: 'bold'}}>{review.name}</div>
                <Rating showText rating={review.rating} />
              </div>

              <p>{review.comment}</p>

              <div style={{display: 'flex'}}>
                <Image
                  style={{width: 120, height: 120, objectFit: 'cover'}}
                  src={review.place.coverUrl || undefined}
                />
                <div style={{marginLeft: 10}}>
                  <b>
                    <a
                      href={`https://www.google.com/maps/place/?q=place_id:${review.place.googlePlaceId}`}
                      target='_blank'
                      rel='noreferrer'>
                      {review.place.name}
                    </a>
                  </b>
                  <br />
                  {review.place.address}
                </div>
              </div>
            </Card>

            {!isEmpty(review.comments) && (
              <Card style={{marginTop: 20}} title={<b>Comments</b>}>
                {[...review.comments].reverse().map((reaction) => (
                  <Comment
                    key={reaction.id}
                    author={
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        {reaction.user.name}
                        {reaction.user.verifiedAt && (
                          <img
                            style={{marginLeft: 3, height: 12}}
                            src={verifiedPng}
                            alt='verified'
                            title='Verified'
                          />
                        )}
                      </div>
                    }
                    avatar={<Avatar src={reaction.user.photoUrl} />}
                    content={reaction.comment}
                    datetime={toDateDistance(reaction.createdAt)}
                  />
                ))}
              </Card>
            )}
          </>
        )}
      </Skeleton>
      {metaTags}
    </>
  );
}
