import cn from 'classnames';
import {NavLink} from 'react-router-dom';
import styles from './HorizontalNav.module.scss';

export enum HorizontalNavSize {
  Small,
  Large,
}

type HorizontalNavProps = {
  size: HorizontalNavSize;
  links?: [string, string][];
};

export default function HorizontalNav({size = HorizontalNavSize.Small, links}: HorizontalNavProps) {
  return (
    <div
      className={cn(
        styles.container,
        styles[size === HorizontalNavSize.Small ? 'small' : 'large'],
      )}>
      {links?.map(([to, text]) => (
        <NavLink
          key={to}
          className={({isActive}) => (isActive ? styles.selected : undefined)}
          to={to}>
          {text}
        </NavLink>
      ))}
    </div>
  );
}
