import {Auth0Provider} from '@auth0/auth0-react';
import 'animate.css';
import {Layout} from 'antd';
import 'antd/dist/antd.css';
import {useCallback} from 'react';
import {usePageTracking} from 'src/hooks';
import {CONFIG} from './constants';
import localState from './lib/localState';
import Routes from './views/Routes';
import styles from './App.module.scss';

export default function App() {
  usePageTracking();

  const handleRedirectCallback = useCallback((appState) => {
    localState['returnTo'] = appState?.returnTo;
  }, []);

  return (
    <Auth0Provider
      domain={CONFIG.AUTH0_DOMAIN}
      clientId={CONFIG.AUTH0_CLIENT_ID}
      audience='hasura'
      redirectUri={window.location.origin + '/auth'}
      onRedirectCallback={handleRedirectCallback}>
      <Layout style={{backgroundColor: 'white'}}>
        {/* <Layout.Header className={styles.headerContainer}>
          <div className={styles.header}>
            <div className={styles.hypeyLogo}>
              <InternalLink className={styles.logoContainer} to='/'>
                <img src={process.env.PUBLIC_URL + '/logo-rounded.svg'} alt='Fudee' />
              </InternalLink>
              <Col sm={24}>
                <InternalLink to='/'>Fudee</InternalLink>
              </Col>
            </div>
          </div>
        </Layout.Header> */}

        <Layout.Content className={styles.contentContainer}>
          <div className={styles.content}>
            <Routes />
          </div>
        </Layout.Content>
        {/* <Layout.Footer className={styles.footer}>
          <span>Fudee &copy;2022</span>
        </Layout.Footer> */}
      </Layout>
    </Auth0Provider>
  );
}
