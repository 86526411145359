import {useCallback} from 'react';
import {getUserId, isAuthed} from 'src/lib/Auth';
import {useLocalStorage} from './useLocalStorage';

export function useAuth() {
  const [jwt, setJwt] = useLocalStorage<string | null>('jwt', null);

  const setAuth = useCallback(
    (jwt) => {
      setJwt(jwt);
    },
    [setJwt],
  );

  return {
    jwt,
    isAuthed: isAuthed(),
    userId: getUserId(),
    setAuth: setAuth,
  };
}
